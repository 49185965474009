import axios from "axios";
import { Urls } from "../utils/urls";
import { Constants } from "../utils/constants";

const baseURL = Constants.URL_BE_API;
const userRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

const adminRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

const cdeaRequestServer = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-type": "application/json",
  },
});

userRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

adminRequestServer.interceptors.request.use(
  (config) => {
    const check = window.location.pathname.includes("/shop/");
    var id;
    if (window.location.pathname.includes(Constants.ADMIN)) {
      if (check) {
        id = parseInt(window.location.pathname.split("/")[2]);
      }
    } else {
      if (check) {
        id = parseInt(window.location.pathname.split("/")[2]);
      }
    }
    const extConstant = id
      ? "_" + Constants.DOMAIN + "_" + id
      : "_" + Constants.DOMAIN;
    const token = localStorage.getItem(Constants.TOKEN_ADMIN + extConstant);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

cdeaRequestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_CDEA);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete userRequestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const requestDownload = axios.create({
  baseURL: Urls.BASE_URL_SERVER_1,
  timeout: 10000,
  responseType: "blob",
});

requestDownload.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete requestDownload.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

const requestForm = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

requestForm.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(Constants.TOKEN_USER);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete requestForm.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export const Api = {
  userRequestServer,
  adminRequestServer,
  cdeaRequestServer,
  requestDownload,
  requestForm,
};
