export const Urls = {
  BASE_URL_SERVER_1: "http://127.0.0.1:7777/api/",
  URL_GET_ADRRESS_BY_POSTALCODE:
    "https://geekfeed-search-japanese-postcode-v1.p.rapidapi.com/search-address",
  LOGIN: "login",
  USER_POINT: "user-point",
  POINT: "point",
  CONFIRM: "confirm",
  PAYMENT_INFO: "payment-info",
  ORDER: "order",
  NOTE: "note",
  LIST: "list",
  SEND_MAIL_PASSWORD: "send-email-forgot-password",
  RESET_PASSWORD: "reset-password",
  SLAG: "slag",
  CONTENT: "content",
  PAGE: "page",
  ALL_PAGE: "all-page",
  SENDMAIL_TOADMIN: "sendmail-toadmin",
  OWNER: "owner",
  CREATE: "create",
  STORE: "store",
  FILTER: "filter",
  CATEGORY: "category",
  USER: "user",
  LIST_AUTHOR: "list-author",
  AUTHOR: "author",
  LIBRARY: "library",
  INDEX: "index",
  SHOW: "show",
  LIST_ALL_AUTHOR: "list-all-authorname",
  LIST_ALL_CATEGORY: "list-all-categoryname",
  BLOCK: "block",
  EDIT: "edit",
  CART: "cart",
  DELETE: "delete",
  LIST_SHOP_ID: "list-shopid",
  LOGOUT: "logout",
  SHOP: "shop",
  UPDATE_STATUS: "update-status",
  MEMBER: "member",
  NOTE_CONTENT: "note-content",
  NOTE_SHOP: "note-shop",
  LIST_POINT_SHOP: "list-point-shop",
  CLICK_ITEM: "click-item",
  ALL_HISTORY_POINT: "all-history-point",
  FILTER_QA: "filter-qa",
  QA: "qa",
  ADMIN: "admin",
  ADMIN_USER: "admin-user",
  CATALOGUE: "catalogue",
  EXPORT_ORDER: "export-order",
  UPLOAD: "upload",
  SALES_MANAGEMENT: "sales-management",
  DAILY: "daily",
  MONTHLY: "monthly",
  EXPORT: "export",
  SETTING_PAGE: "setting-page",
  HEADER: "header",
  CREATE_EDIT: "create-edit",
  FOOTER: "footer",
  BOTTOM: "bottom",
  CATEGORY_NEW: "category-news",
  NEWS: "news",
  DAILY_DETAIL: "daily-detail",
  MONTHLY_SUM: "monthly-sum",
  TAG: "tag",
  RANK: "rank",
  DAY: "day",
  PROFILE: "profile",
  FIXED_PAGE: "fixed_page",
  SETTING: "setting",
  SMTP: "smtp",
  MAIL: "mail",
  TEST_SEND_MAIL: "test-send-mail",
  POPUP: "popup",
  DETAIL: "detail",
  INQUIRY: "inquiry",
  SALE_INFO: "sale-info",
  SALES_SETTING: "sales-setting",
  TIMING_MAIL: "timing-mail",
  FAVORITE: "favorite",
  SALE: "sale",
  URL: "url",
  GENERAL: "general",
  PAYPAL: "paypal",
  ONETIME_REDIRECT: "onetime-redirect",
  PAYMENT: "payment",
  LIST_NOTE: "list-note",
  CANCEL: "cancel",
  SHOP_INFO: "shop-info",
  RECOMMEND: "recommend",
  NEW_LETTER: "newsletter",
  SURVEY: "survey",
  UPDATE: "update",
  VOTE: "vote",
  SNS_LOGIN: "sns-login",
  UPDATE_TIME_READ: "update-time-read",
  UNREAD: "unread",
  READ: "read",
  CHAPTER: "chapter",
  PURCHASE_POINT: "purchase-point",
  NEWS_ADMIN: "news-admin",
  SALE_OFFER: "sale-offer",
  SALE_OTHER: "sale-other",
  SALE_BASIC: "sale-basic",
  SALE_ADVANCE: "sale-advance",
  TOKEN: "token",
  STRIPE: "stripe",
  CREATE_PLAN: "create-plan",
  NO_CONTENT: "no-category",
  INFO: "info",
  TRANSFER: "transfer",
  CANCEL_SUB: "cancel-sub",
  STORE_ANSWER: "store-answer",
  ACTIVE_SHOP: "active-shop",
  SNS: "sns",
  COLOR: "color",
  AVATAR: "avatar",
  CHAT: "chat",
  ALL: "all",
  MESSAGE: "message",
  SEND: "send",
  CHAPTER_NEW: "chapter_new",
  LOG_ACTIVE: "log-activity",
  POINT_BONUS: "point-bonus",
  SLAG_CONDITION: "slag-condition",
  CREATE_NO_SLAG: "create-no-slag",
  IS_NEW: "is-new",
  CHECK_ACTIVE: "check-active",
  OFFER_TYPE: "offer-type",
  OFFER_PRICE: "offer-price",
  OFFER_SHOP: "offer-shop",
  SETTINGBASIC: "settingBasic",
  UPDATEBASIC: "updateBasic",
  SETTINGSALE: "settingSale",
  UPDATESALE: "updateSale",
  AUTHOR_CONTENT: "author-content",
  LIMIT: "limit",
  CHECKOUT_PAYPAL_ONETIME: "checkout-paypal-onetime",
  CHECKOUT_PAYPAL_SUBCRIPTION: "checkout-paypal-subcript",
  EX_PAYPAL_ONETIME: "execute-paypal-onetime",
  EX_PAYPAL_SUBCRIPTION: "execute-paypal-subcript",
  SLAG_TOKEN: "slag-token",
  PRE_SALE: "pre-sales",
  NO_CONDITION: "no-condition",
  SHOP_DOMAIN: "shop-domain",
  COUNT_VIEW_PDF: "count-view-pdf",
};
